import React, { useState } from "react";
import { useRouteError } from "react-router-dom";
import "./styles.scss";

export default function ErrorBoundary() {
    const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
    const error = useRouteError();

    return (
        <div className="error-boundary">
            <h1>Whoops !</h1>
            <p className="text text--big">
                Quelque chose s&apos;est mal passé. Essayez à nouveau plus tard.
            </p>
            {error?.message && (
                <>
                    <p
                        className="text text--link"
                        onClick={() => setIsErrorDisplayed(!isErrorDisplayed)}>
                        En savoir plus
                    </p>
                    {isErrorDisplayed ? (
                        <p className="text">{error?.message}</p>
                    ) : null}
                </>
            )}
        </div>
    );
}
