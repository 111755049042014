const etapes = {
    bronze: [
        {
            title: "Diagnostic écologique correspondant aux attentes du niveau bronze",
            menu_title: "Diagnostic écologique",
            etape: 1,
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_ecologique",
                    commentaire: null,
                    default_value: "oui",
                    disabled: true,
                },
            ],
            is_valid: false,
        },
        {
            title: "Définition avec la structure naturaliste du plan d'action et des actions à mettre en oeuvre",
            menu_title: "Plan d'action",
            etape: 2,
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_structure_naturaliste_radio",
                    commentaire:
                        "candidature_auditeur_avis_structure_naturaliste_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Référent biodiversité du club",
            menu_title: "Référent biodiversité",
            etape: 3,
            fields: [
                {
                    autocomplete_titre: [
                        "Nom du référent :",
                        "candidature_engagement_bronze_referent_bio_prenom",
                        "candidature_engagement_bronze_referent_bio_nom",
                    ],
                    title: null,
                    radio: "candidature_auditeur_avis_referent_biodiversite_radio",
                    commentaire:
                        "candidature_auditeur_avis_referent_biodiversite_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Mise en oeuvre de deux actions préconisées et définies avec la structure naturaliste",
            menu_title: "Actions préconisées",
            etape: 4,
            field_key:
                "candidature_auditeur_avis_actions_preconises_bronze_repeater",
            autocomplete:
                "candidature_engagement_bronze_justificatif_actions_repeater",
            autocomplete_titre:
                "candidature_engagement_bronze_justificatif_actions_titre",
            multiple: true,
            fields: [],
            is_valid: false,
        },
        {
            title: "Mise en oeuvre d’une action de sensibilisation à la biodiversité (ponctuelle ou pérenne) vers les pratiquants",
            menu_title:
                "Sensibilisation (ponctuelle ou pérenne) vers les pratiquants",
            field_key:
                "candidature_auditeur_avis_sensibilisation_biodiversite_bronze",
            autocomplete:
                "candidature_engagement_bronze_justification_actions_sensi_titre",
            etape: 5,
            fields: [],
            is_valid: false,
        },
        {
            title: "Suite à l’obtention du Label, mise en place de 5 actions préconisées et définies avec la structure naturaliste",
            menu_title: "Poursuite des actions",
            etape: 6,
            fields: [
                {
                    title: null,
                    commentaire:
                        "candidature_auditeur_avis_poursuite_actions_bronze",
                    radio: null,
                    placeholder:
                        "Indiquez ici la façon dont le club voit la poursuite de sa démarche dans l’obtention du Label et les actions qu'il va entreprendre ou poursuivre pour rester dans cette démarche.",
                },
            ],
            is_valid: false,
        },
        {
            title: "Points forts de l'engagement du club",
            menu_title: "Points forts de l'engagement du club",
            etape: 7,
            fields: [
                {
                    title: null,
                    radio: null,
                    commentaire: "candidature_auditeur_avis_points_forts",
                    placeholder:
                        "Indiquez les points forts que vous identifiez dans la démarche du club. Ceux-ci peuvent être : une action / organisation méritant d'être mise en valeur OU tout ce que l’auditeur juge pertinent de faire ressortir.",
                },
            ],
            is_valid: false,
        },
        {
            title: "Avis de l'auditeur",
            menu_title: "Avis de l'auditeur",
            etape: 8,
            fields: [
                {
                    title: null,
                    radio: null,
                    commentaire: "candidature_auditeur_avis_avis_auditeur",
                    placeholder:
                        "Exprimez votre avis quant à l'appropriation du golf de cette démarche pour la connaissance, l'action et la sensibilisation de la biodiversité",
                },
            ],
            is_valid: false,
        },
    ],
    argent: [
        {
            title: "Diagnostic écologique correspondant aux attentes du niveau argent",
            menu_title: "Diagnostic écologique",
            etape: 1,
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_ecologique",
                    commentaire: null,
                    default_value: "oui",
                    disabled: true,
                },
            ],
            is_valid: false,
        },
        {
            title: "Définition avec la structure naturaliste du plan d'action et des actions à mettre en oeuvre",
            menu_title: "Plan d'action",
            etape: 2,
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_structure_naturaliste_radio",
                    commentaire:
                        "candidature_auditeur_avis_structure_naturaliste_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Référent biodiversité du club",
            menu_title: "Référent biodiversité",
            etape: 3,
            fields: [
                {
                    autocomplete_titre: [
                        "Nom du référent :",
                        "candidature_engagement_argent_referent_bio_prenom",
                        "candidature_engagement_argent_referent_bio_nom",
                    ],
                    title: null,
                    radio: "candidature_auditeur_avis_referent_biodiversite_radio",
                    commentaire:
                        "candidature_auditeur_avis_referent_biodiversite_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Mise en oeuvre de cinq actions préconisées et définies avec la structure naturaliste",
            menu_title: "Actions préconisées",
            etape: 4,
            autocomplete:
                "candidature_engagement_argent_justificatif_actions_repeater",
            autocomplete_titre:
                "candidature_engagement_argent_justificatif_actions_titre",
            field_key:
                "candidature_auditeur_avis_actions_preconises_argent_repeater",
            multiple: true,
            fields: [],
            is_valid: false,
        },
        {
            title: "Mise en oeuvre d’une action de sensibilisation pérenne à la biodiversité vers les pratiquants",
            menu_title: "Sensibilisation pérenne vers les pratiquants",
            field_key:
                "candidature_auditeur_avis_sensibilisation_biodiversite_argent",
            autocomplete:
                "candidature_engagement_argent_justification_actions_sensi_titre",
            etape: 5,
            fields: [],
            is_valid: false,
        },
        {
            title: "Sensibilisation des salariés à la biodiversité",
            menu_title: "Sensibilisation des salariés à la biodiversité",
            field_key:
                "candidature_auditeur_avis_sensibilisation_salaries_biodiversite_argent",
            autocomplete:
                "candidature_engagement_argent_justification_actions_sensi_salaries_titre",
            etape: 6,
            fields: [],
            is_valid: false,
        },
        {
            title: "Suite à l’obtention du Label, mise en place de 8 actions préconisées et définies avec la structure naturaliste",
            menu_title: "Poursuite des actions",
            etape: 7,
            fields: [
                {
                    title: null,
                    commentaire:
                        "candidature_auditeur_avis_poursuite_actions_argent",
                    radio: null,
                    placeholder:
                        "Indiquez ici la façon dont le club voit la poursuite de sa démarche dans l’obtention du Label et les actions qu'il va entreprendre ou poursuivre pour rester dans cette démarche.",
                },
            ],
            is_valid: false,
        },
        {
            title: "Points forts de l'engagement du club",
            menu_title: "Points forts de l'engagement du club",
            etape: 8,
            fields: [
                {
                    title: null,
                    radio: null,
                    commentaire: "candidature_auditeur_avis_points_forts",
                    placeholder:
                        "Indiquez les points forts que vous identifiez dans la démarche du club. Ceux-ci peuvent être : une action / organisation méritant d'être mise en valeur OU tout ce que l’auditeur juge pertinent de faire ressortir.",
                },
            ],
            is_valid: false,
        },
        {
            title: "Avis de l'auditeur",
            menu_title: "Avis de l'auditeur",
            etape: 9,
            fields: [
                {
                    title: null,
                    radio: null,
                    commentaire: "candidature_auditeur_avis_avis_auditeur",
                    placeholder:
                        "Exprimez votre avis quant à l'appropriation du golf de cette démarche pour la connaissance, l'action et la sensibilisation de la biodiversité",
                },
            ],
            is_valid: false,
        },
    ],
    or: [
        {
            title: "Diagnostic écologique correspondant aux attentes du niveau or",
            menu_title: "Diagnostic écologique",
            etape: 1,
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_ecologique",
                    commentaire: null,
                    default_value: "oui",
                    disabled: true,
                },
            ],
            is_valid: false,
        },
        {
            title: "Définition avec la structure naturaliste du plan d'action et des actions à mettre en oeuvre",
            menu_title: "Plan d'action",
            etape: 2,
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_structure_naturaliste_radio",
                    commentaire:
                        "candidature_auditeur_avis_structure_naturaliste_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Référent biodiversité du club",
            menu_title: "Référent biodiversité",
            etape: 3,
            fields: [
                {
                    autocomplete_titre: [
                        "Nom du référent :",
                        "candidature_engagement_or_referent_bio_prenom",
                        "candidature_engagement_or_referent_bio_nom",
                    ],
                    title: null,
                    radio: "candidature_auditeur_avis_referent_biodiversite_radio",
                    commentaire:
                        "candidature_auditeur_avis_referent_biodiversite_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Mise en oeuvre de toutes les actions préconisées et définies avec la structure naturaliste",
            menu_title: "Actions préconisées",
            etape: 4,
            autocomplete:
                "candidature_engagement_or_justificatif_actions_repeater",
            autocomplete_titre:
                "candidature_engagement_or_justificatif_actions_titre",
            field_key:
                "candidature_auditeur_avis_actions_preconises_or_repeater",
            multiple: true,
            fields: [],
            is_valid: false,
        },
        {
            title: "Mise en oeuvre d'une action de sensibilisation ponctuelle et annuelle à la biodiversité vers les pratiquants",
            menu_title:
                "Sensibilisation ponctuelle et annuelle vers les pratiquants",
            etape: 5,
            field_key:
                "candidature_auditeur_avis_sensibilisation_biodiversite_or",
            autocomplete:
                "candidature_engagement_or_mep_actions_bio_ponctuelle_titre",
            fields: [],
            is_valid: false,
        },
        {
            title: "Mise en oeuvre d'une action de sensibilisation à la biodiversité pérenne vers les pratiquants",
            menu_title: "Sensibilisation pérenne vers les pratiquants",
            etape: 6,
            field_key:
                "candidature_auditeur_avis_sensibilisation_biodiversite_perenne_or",
            autocomplete:
                "candidature_engagement_or_mep_actions_bio_perenne_titre",
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_sensibilisation_biodiversite_perenne_or_radio",
                    commentaire:
                        "candidature_auditeur_avis_sensibilisation_biodiversite_perenne_or_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Sensibilisation des salariés à la biodiversité",
            menu_title: "Sensibilisation des salariés",
            etape: 7,
            field_key:
                "candidature_auditeur_avis_sensibilisation_salaries_biodiversite_or",
            autocomplete:
                "candidature_engagement_or_mep_actions_bio_salaries_titre",
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_sensibilisation_salaries_biodiversite_or_radio",
                    commentaire:
                        "candidature_auditeur_avis_sensibilisation_salaries_biodiversite_or_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Mise en place d'une action en faveur de la biodiversité en collaboration avec les acteurs du territoire",
            menu_title: "Action territoriale",
            etape: 8,
            field_key:
                "candidature_auditeur_avis_action_biodiversite_collaboration_or",
            autocomplete:
                "candidature_engagement_or_mep_actions_bio_territoire_titre",
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_action_biodiversite_collaboration_or_radio",
                    commentaire:
                        "candidature_auditeur_avis_action_biodiversite_collaboration_or_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Implication dans un programme d'expérimentation / R&D validé avec la FFGolf",
            menu_title:
                "Implication dans un programme d'expérimentation / R&D validé avec la FFGolf",
            etape: 9,
            field_key: "candidature_auditeur_avis_programme_rd_or",
            autocomplete: "candidature_engagement_or_implication_rd_titre",
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_programme_rd_or_radio",
                    commentaire:
                        "candidature_auditeur_avis_programme_rd_or_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Aider et accompagner d'autres clubs dans les démarches du Label",
            menu_title: "Aide & accompagnement",
            etape: 10,
            field_key: "candidature_auditeur_avis_aide_demarches_or",
            autocomplete:
                "candidature_engagement_or_aide_clubs_demarches_titre",
            fields: [
                {
                    title: null,
                    radio: "candidature_auditeur_avis_aide_demarches_or_radio",
                    commentaire:
                        "candidature_auditeur_avis_aide_demarches_or_commentaire",
                },
            ],
            is_valid: false,
        },
        {
            title: "Suite à l'obtention du label, incitation à entreprendre de nouvelles actions",
            menu_title: "Entreprendre de nouvelles actions",
            etape: 11,
            fields: [
                {
                    title: null,
                    radio: null,
                    commentaire:
                        "candidature_auditeur_avis_nouvelles_actions_or",
                    placeholder:
                        "Indiquez ici la façon dont le club voit la poursuite de sa démarche dans l'obtention du Label et les actions qu'il va entreprendre ou poursuivre pour rester dans cette démarche d'excellence",
                },
            ],
            is_valid: false,
        },
        {
            title: "Points forts de l'engagement du club",
            menu_title: "Points forts de l'engagement du club",
            etape: 12,
            fields: [
                {
                    title: null,
                    radio: null,
                    commentaire: "candidature_auditeur_avis_points_forts",
                    placeholder:
                        "Indiquez les points forts que vous identifiez dans la démarche du club. Ceux-ci peuvent être : une action / organisation méritant d'être mise en valeur OU tout ce que l’auditeur juge pertinent de faire ressortir.",
                },
            ],
            is_valid: false,
        },
        {
            title: "Avis de l'auditeur",
            menu_title: "Avis de l'auditeur",
            etape: 13,
            fields: [
                {
                    title: null,
                    radio: null,
                    commentaire: "candidature_auditeur_avis_avis_auditeur",
                    placeholder:
                        "Exprimez votre avis quant à l'appropriation du golf de cette démarche pour la connaissance, l'action et la sensibilisation de la biodiversité",
                },
            ],
            is_valid: false,
        },
    ],
};

export default etapes;
