import React, { useEffect, useState } from "react";
import { useParams, useOutletContext, Navigate } from "react-router-dom";
import etapes from "./etapes";
import "./styles.scss";

// Components
import AuditViewMenu from "./Menu";
import AuditViewEtape from "./Etape";

function AuditView() {
    const {
        token,
        audits,
        setAudits,
        selectedAudit,
        setSelectedAudit,
        setNotes,
        saveAudit,
        setIsFormModified,
    } = useOutletContext();
    const { id } = useParams();
    const [audit, setAudit] = useState();
    const [inputs, setInputs] = useState({});
    const [lastEtape, setLastEtape] = useState(1);
    const [etape, setEtape] = useState(1);
    const [etapeData, setEtapeData] = useState();
    const [canSubmitAudit, setCanSubmitAudit] = useState(false);
    const [forceModify, setForceModify] = useState(false);

    // Fonction pour passer à l'étape suivante dans les champs
    const nextStep = (e) => {
        e.preventDefault();

        if (etape < etapeData.length) {
            setEtape((oldEtape) => {
                setLastEtape(oldEtape);

                return oldEtape + 1;
            });
        }
    };

    const checkSingleEtapeValidity = (step) => {
        if (etapeData) {
            const etapesCopy = [...etapeData];

            etapesCopy.forEach((etapeCopy, index) => {
                if (etapeCopy.etape && etapeCopy.etape === step) {
                    etapesCopy[index].is_seen = true;

                    // Check si tous les champs de l'étape sont remplis
                    etapesCopy[index].is_valid = false;

                    if (etapeCopy.fields.length > 1) {
                        const allChecks = [];

                        etapeCopy.fields.forEach((field) => {
                            let fieldCheck = false;

                            if (field.radio && field.commentaire) {
                                if (
                                    inputs[field.radio] &&
                                    inputs[field.commentaire] &&
                                    inputs[field.commentaire].length
                                ) {
                                    fieldCheck = true;
                                }
                            } else if (field.radio && !field.commentaire) {
                                if (inputs[field.radio]) {
                                    fieldCheck = true;
                                }
                            } else if (field.commentaire && !field.radio) {
                                if (
                                    inputs[field.commentaire] &&
                                    inputs[field.commentaire].length
                                ) {
                                    fieldCheck = true;
                                }
                            }

                            allChecks.push(fieldCheck);
                        });

                        etapesCopy[index].is_valid = !allChecks.some(
                            (item) => !item
                        );
                    } else if (etapeCopy.fields.length) {
                        // Si on a un radio et un commentaire
                        if (
                            etapeCopy.fields[0].radio &&
                            etapeCopy.fields[0].commentaire
                        ) {
                            if (
                                inputs[etapeCopy.fields[0].radio] &&
                                inputs[etapeCopy.fields[0].commentaire] &&
                                inputs[etapeCopy.fields[0].commentaire].length
                            ) {
                                etapesCopy[index].is_valid = true;
                            }
                        } else if (
                            etapeCopy.fields[0].radio &&
                            !etapeCopy.fields[0].commentaire
                        ) {
                            if (inputs[etapeCopy.fields[0].radio]) {
                                etapesCopy[index].is_valid = true;
                            }
                        } else if (
                            etapeCopy.fields[0].commentaire &&
                            !etapeCopy.fields[0].radio
                        ) {
                            if (
                                inputs[etapeCopy.fields[0].commentaire] &&
                                inputs[etapeCopy.fields[0].commentaire].length
                            ) {
                                etapesCopy[index].is_valid = true;
                            }
                        }
                    }
                }
            });

            setEtapeData(etapesCopy);
        }
    };

    // Set du state pour les composants parents
    useEffect(() => {
        if (audits) {
            audits.forEach((ref, index) => {
                if (ref?.ID === parseInt(id, 10)) {
                    setAudit(ref);
                    setSelectedAudit(index);
                }
            });
        }
    }, [id, audits, setAudit, setSelectedAudit]);

    // Initialisation des variables de l'étape
    useEffect(() => {
        // Set du audit.is_seen si ce n'est pas le cas
        if (audit && !audit?.is_seen) {
            audit.is_seen = true;
            const auditsStateCopy = [...audits];
            auditsStateCopy[selectedAudit] = audit;

            setAudits(auditsStateCopy);
        }

        if (audit?.fields) {
            const { fields } = audit;

            etapes[audit?.label_vise].forEach((field) => {
                if (field.fields && field.fields.length) {
                    field.fields.forEach((deepField) => {
                        if (deepField.default_value) {
                            fields[deepField.radio] = deepField.default_value;
                        }
                    });
                }
            });

            setInputs(fields);
        }

        if (audit?.notes) {
            setNotes(audit?.notes);
        } else {
            setNotes("");
        }

        if (
            audit?.label_vise &&
            etapes[audit?.label_vise] &&
            !etapeData &&
            Object.keys(inputs).length
        ) {
            // Il faut préfiltrer la donnée pour autocomplete les champs avec ceux de la donnée du club
            etapes[audit?.label_vise].forEach((field, index) => {
                if (field.fields && field.fields.length) {
                    field.fields.forEach((deepField, deepFieldIndex) => {
                        if (deepField.autocomplete_titre) {
                            let newTitle = deepField.autocomplete_titre;

                            if (Array.isArray(newTitle)) {
                                let newTitleTemp = "";

                                newTitle.forEach((titre) => {
                                    newTitleTemp = `${newTitleTemp} ${
                                        audit.fields[titre]
                                            ? audit.fields[titre]
                                            : titre
                                    }`;
                                });

                                newTitle = newTitleTemp;
                            } else if (audit.fields[newTitle]) {
                                newTitle = audit.fields[newTitle];
                            }

                            etapes[audit?.label_vise][index].fields[
                                deepFieldIndex
                            ].title = newTitle;
                        }
                    });
                }
                if (
                    field.autocomplete &&
                    audit.fields[field.autocomplete] &&
                    audit.fields[field.autocomplete].length
                ) {
                    const newFields = [];
                    let fieldAutocompleteTitre = field?.autocomplete_titre;
                    let fieldKey = field.autocomplete;

                    if (field.field_key) {
                        fieldKey = field.field_key;
                    }

                    if (!fieldAutocompleteTitre) {
                        fieldAutocompleteTitre = field.autocomplete;
                    }

                    if (field?.multiple) {
                        audit.fields[field.autocomplete].forEach(
                            (auditField, auditIndex) => {
                                const newField = {};

                                newField.title =
                                    auditField[fieldAutocompleteTitre];
                                newField.radio = `${fieldKey}_radio_${auditIndex}`;
                                newField.commentaire = `${fieldKey}_commentaire_${auditIndex}`;

                                newFields.push(newField);
                            }
                        );
                    } else {
                        newFields.push({
                            title: audit.fields[fieldAutocompleteTitre],
                            radio: `${fieldKey}_radio`,
                            commentaire: `${fieldKey}_commentaire`,
                        });
                    }

                    if (newFields.length) {
                        etapes[audit?.label_vise][index].fields = newFields;
                    }
                }

                // Il faut checker si le champ a déjà été rempli
                if (
                    audit &&
                    audit.etape_validity &&
                    audit.etape_validity[field.etape] &&
                    audit.etape_validity[field.etape].is_seen
                ) {
                    etapes[audit?.label_vise][index].is_seen = true;
                    etapes[audit?.label_vise][index].is_valid =
                        audit.etape_validity[field.etape].is_valid;
                }
            });

            setEtapeData(etapes[audit?.label_vise]);
        }
    }, [audit, setNotes, etapeData, setEtapeData, inputs]);

    // Au update des champs, mettre à jour le state et le localStorage
    useEffect(() => {
        if (
            audits &&
            audit &&
            inputs &&
            Object.keys(inputs).length &&
            etapeData
        ) {
            // On trouve d'abord l'audit correspondant
            const correspondingAudit = audits[selectedAudit];

            if (correspondingAudit) {
                // Puis on récupère les valeurs entrées et on boucle dessus pour mettre à jour l'audit
                Object.keys(inputs).forEach((key) => {
                    audit.fields[key] = inputs[key];
                });

                // Puis on check la validité de tous les champs pour savoir si on peut upload
                if (etapeData && etapeData.length) {
                    checkSingleEtapeValidity(etape);

                    const cantUpload = etapeData.some(
                        (step) => step.is_valid === false
                    );

                    setCanSubmitAudit(!cantUpload);

                    // On mets l'état des étapes dans le cache pour pouvoir le récupérer au chargement
                    const cacheEtapeValidity = {};

                    etapeData.forEach((step) => {
                        cacheEtapeValidity[step.etape] = {
                            etape: step.etape,
                            is_seen: step?.is_seen,
                            is_valid: step?.is_valid,
                        };
                    });

                    audit.etape_validity = cacheEtapeValidity;
                }

                // Puis on mets à jour les audits en général
                const auditsStateCopy = [...audits];
                auditsStateCopy[selectedAudit] = audit;

                setAudits(auditsStateCopy);
                setIsFormModified(true);
            }
        }
    }, [inputs, setAudits]);

    // A chaque changement d'étape, il faut checker si les champs sont valides
    useEffect(() => {
        if (lastEtape !== etape) {
            checkSingleEtapeValidity(lastEtape);
        }
    }, [etape, lastEtape]);

    const submitAudit = async (e) => {
        e.preventDefault();

        const res = await saveAudit(true);

        if (res && res?.response) {
            audit.etape = 10;

            const auditsStateCopy = [...audits];
            auditsStateCopy[selectedAudit] = audit;

            setAudits(auditsStateCopy);
            setForceModify(false);
            setIsFormModified(false);
        }
    };

    const handleModifyAudit = (e) => {
        e.preventDefault();

        setForceModify(true);
    };

    let content = null;

    if (token) {
        if (audit) {
            if ((audit.is_submitted || audit.etape >= 10) && !forceModify) {
                const randomQueryString = (Math.random() + 1)
                    .toString(36)
                    .substring(7);

                content = (
                    <section className="audit-view audit-view__ended">
                        <section className="audit-view__ended__wrapper">
                            <span className="icon-tree" />

                            <h1>Audit terminé</h1>

                            <p className="sous-titre">
                                Audit pour le{" "}
                                <b>{audits[selectedAudit]?.club?.post_title}</b>
                                {audits[selectedAudit]?.club?.fields
                                    ?.club_adresse ? (
                                    <>
                                        <br />
                                        {
                                            audits[selectedAudit]?.club?.fields
                                                ?.club_adresse
                                        }
                                        ,{" "}
                                        {
                                            audits[selectedAudit]?.club?.fields
                                                ?.club_code_postal
                                        }{" "}
                                        {
                                            audits[selectedAudit]?.club?.fields
                                                ?.club_ville
                                        }
                                    </>
                                ) : null}
                            </p>

                            <div className="cotation">
                                <span
                                    className={
                                        audits[selectedAudit].label_vise
                                    }>
                                    {audits[selectedAudit].label_vise}
                                </span>
                            </div>
                            <a
                                href={`${process.env.REACT_APP_PLATEFORME_LINK}candidature/${audits[selectedAudit].ID}/audit-pdf/?${randomQueryString}`}
                                className="btn"
                                target="_blank"
                                style={{ marginBottom: "15px" }}
                                rel="noreferrer">
                                Rapport d&lsquo;audit
                            </a>

                            {audits[selectedAudit]?.etape <= 10 ? (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleModifyAudit}
                                    style={{ marginBottom: "15px" }}>
                                    Modifier le contenu de mon audit
                                </button>
                            ) : (
                                ""
                            )}

                            {audits[selectedAudit]?.etape === 10 ? (
                                <a
                                    href={`${process.env.REACT_APP_PLATEFORME_LINK}espace-audit/${audits[selectedAudit].ID}`}
                                    className="btn"
                                    target="_blank"
                                    rel="noreferrer">
                                    Signature du rapport d&lsquo;audit
                                </a>
                            ) : (
                                ""
                            )}
                        </section>
                    </section>
                );
            } else {
                content = (
                    <section className="audit-view">
                        <AuditViewMenu
                            etape={etape}
                            setEtape={setEtape}
                            setLastEtape={setLastEtape}
                            etapeData={etapeData}
                        />
                        <form className="audit-view__form">
                            {etapeData
                                ? etapeData.map((data) => (
                                      <AuditViewEtape
                                          etape={etape}
                                          activeEtape={data.etape}
                                          inputs={inputs}
                                          setInputs={setInputs}
                                          etapeData={data}
                                          handleButtonEtape={nextStep}
                                          handleButtonSubmit={submitAudit}
                                          isLastEtape={
                                              data.etape >= etapeData.length
                                          }
                                          canSubmitAudit={canSubmitAudit}
                                      />
                                  ))
                                : null}
                        </form>
                    </section>
                );
            }
        }
    } else {
        <Navigate to="/" replace />;
    }

    return content;
}

export default AuditView;
