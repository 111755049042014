import React, { useState, useEffect } from "react";
import {
    Outlet,
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import axios from "axios";
import "./styles.scss";

// Hooks
import useToken from "../../hooks/useToken";
import useDetectOnline from "../../hooks/useDetectOnline";
import useCallbackPrompt from "../../hooks/useCallbackPrompt";

// Components
import Header from "../Header";
import Footer from "../Footer";
import AuditsNotes from "../Audits/Notes";
import AuditsAttendus from "../Audits/Attendus";
import LoadingModal from "../LoadingModal";
import DisconnectModal from "../Disconnect";
import ResyncModal from "../ResyncModal";

// Utils
import getWholeToken from "../../utils/getWholeToken";
import SavingModal from "../SavingModal/SavingModal";

function Layout() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { token, setToken } = useToken();
    const { pathname } = useLocation();
    const { isOnline } = useDetectOnline();
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingAudits, setLoadingAudits] = useState(false);
    const [user, setUser] = useState();
    const [audits, setAudits] = useState();
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [notes, setNotes] = useState(false);
    const [isNotesActive, setIsNotesActive] = useState(false);
    const [isAttendusActive, setIsAttendusActive] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isResyncing, setIsResyncing] = useState(false);
    const [isResyncModalActive, setIsResyncModalActive] = useState(false);
    const [isFormModified, setIsFormModified] = useState(false);
    const [showPrompt, setShowPrompt, lastUrlClicked] =
        useCallbackPrompt(isFormModified);
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    // Fonction de sauvegarde
    const saveAudit = async (isSubmission = false) => {
        setIsSaving(true);

        // Récupération du informations
        const { ID, fields } = audits[selectedAudit];
        const formData = new FormData();

        // Construction de la donnée - Petit hack, on envoie que les champs qui commencent par "candidature_auditeur_avis" car la structure est censée respecter cette nomemclature
        Object.keys(fields).forEach((fieldKey) => {
            if (fieldKey.startsWith("candidature_auditeur_avis")) {
                if (fields[fieldKey] === Object(fields[fieldKey])) {
                    formData.append(fieldKey, JSON.stringify(fields[fieldKey]));
                } else {
                    formData.append(fieldKey, fields[fieldKey]);
                }
            }
        });

        // Si c'est une soumission, on ajoute un argument qu'on va push dans la donnée pour ajouter une post_meta de l'autre côté
        if (isSubmission) {
            formData.append("soumission", true);
        }

        // Faut pas oublier d'envoyer les notes pour pouvoir les récupérer à la prochaine resync
        if (notes) {
            formData.append("notes", notes);
        }

        return axios
            .post(`${process.env.REACT_APP_API_HOST}/audits/${ID}`, formData, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                // TODO : Gestion des erreurs
                setIsSaving(false);

                return response.data;
            });
    };

    // Récupération User
    const getUser = () => {
        const { userId } = getWholeToken();

        // Récupération des users
        fetch(`${process.env.REACT_APP_API_HOST}/users/${userId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                localStorage.setItem("user", JSON.stringify(data.response));
                setUser(data.response);
                setLoadingUsers(false);
            });
    };

    // Récupération Audits
    const getAudits = () => {
        const { userId } = getWholeToken();

        fetch(`${process.env.REACT_APP_API_HOST}/audits?auditeur=${userId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const auditsTemp = data.response.audits;

                // Il faut checker si le repeater des actions est présent dans les champs
                auditsTemp.forEach((audit, index) => {
                    if (audit.fields) {
                        Object.keys(audit.fields).forEach((fieldKey) => {
                            const regexMatch = fieldKey.match(
                                /(candidature_auditeur_avis_actions_preconises_(bronze|argent|or)_repeater)$/g
                            );

                            if (
                                regexMatch &&
                                audit.fields[fieldKey] &&
                                audit.fields[fieldKey].length
                            ) {
                                const auditFieldCopy = [
                                    ...audit.fields[fieldKey],
                                ];

                                auditFieldCopy.forEach((oldField, i) => {
                                    if (oldField.radio) {
                                        auditsTemp[index].fields[
                                            `${fieldKey}_radio_${i}`
                                        ] = oldField.radio;
                                    }

                                    if (oldField.commentaire) {
                                        auditsTemp[index].fields[
                                            `${fieldKey}_commentaire_${i}`
                                        ] = oldField.commentaire;
                                    }
                                });
                            }
                        });
                    }
                });

                localStorage.setItem("audits", JSON.stringify(auditsTemp));
                setAudits(auditsTemp);
                setLoadingAudits(false);
            });
    };

    // Génération de la donnée
    const getData = (forceResync = false) => {
        if ((searchParams.has("resync") && !isResyncing) || forceResync) {
            setIsResyncing(true);
            setLoadingAudits(true);
            setLoadingUsers(true);

            getUser();
            getAudits();
        }

        const auditsLocalStorage = localStorage.getItem("audits");

        if (auditsLocalStorage) {
            // faut préfiltrer
            const auditsToFilter = JSON.parse(auditsLocalStorage);
            const finalAudits = [];

            if (auditsToFilter) {
                auditsToFilter.forEach((audit) => {
                    if (audit.club) {
                        finalAudits.push(audit);
                    }
                });
            }

            setAudits(finalAudits);
        }

        const userLocalStorage = localStorage.getItem("user");

        if (userLocalStorage) {
            setUser(JSON.parse(userLocalStorage));
        }
    };

    // Récupération du localStorage + check de l'URL pour devoir resync si besoin
    useEffect(() => {
        if (token) {
            getData();
        }
    }, [token]);

    // Lorsque la reync est finie, on navigue vers la home
    useEffect(() => {
        if (isResyncing) {
            if (!loadingAudits && !loadingUsers) {
                setIsResyncing(false);

                searchParams.delete("resync");
                setSearchParams(searchParams);
            }
        }
    }, [
        isResyncing,
        loadingAudits,
        loadingUsers,
        navigate,
        searchParams,
        setSearchParams,
    ]);

    // Au changement du state des audits, mettre à jour le localStorage en même temps
    useEffect(() => {
        if (audits) {
            localStorage.setItem("audits", JSON.stringify(audits));
        }
    }, [audits]);

    // Si on est sur l'accueil, il faut reset le selectedAudit à la fermeture de l'overlay
    useEffect(() => {
        if (pathname === "/" && selectedAudit && !isNotesActive) {
            setSelectedAudit(null);
            setNotes(false);
            setIsFormModified(false);
        }
    }, [
        isNotesActive,
        setSelectedAudit,
        setNotes,
        pathname,
        selectedAudit,
        setIsFormModified,
    ]);

    const handleDeconnexion = (e) => {
        e.preventDefault();

        setIsModalOpen(false);

        // On vide le localStorage
        localStorage.removeItem("token");
        localStorage.removeItem("audits");
        localStorage.removeItem("user");

        // Puis on reset le state
        setToken(null);

        // Puis enfin on redirige
        navigate("/");

        window.location.reload();
    };

    return (
        <main>
            <Header
                user={user}
                setToken={setToken}
                audits={audits}
                selectedAudit={selectedAudit}
                setIsNotesActive={setIsNotesActive}
                setIsAttendusActive={setIsAttendusActive}
                isOnline={isOnline}
                isSaving={isSaving}
                saveAudit={saveAudit}
                setIsModalOpen={setIsModalOpen}
                setIsResyncModalActive={setIsResyncModalActive}
            />

            <Outlet
                context={{
                    token,
                    setToken,
                    user,
                    getUser,
                    audits,
                    setAudits,
                    getAudits,
                    selectedAudit,
                    setSelectedAudit,
                    isNotesActive,
                    setIsNotesActive,
                    isAttendusActive,
                    setIsAttendusActive,
                    isOnline,
                    isSaving,
                    setIsSaving,
                    saveAudit,
                    isModalOpen,
                    setIsModalOpen,
                    isResyncing,
                    setIsResyncing,
                    loadingUsers,
                    setLoadingUsers,
                    loadingAudits,
                    setLoadingAudits,
                    notes,
                    setNotes,
                    isFormModified,
                    setIsFormModified,
                }}
            />

            <LoadingModal isActive={isSaving} text="Enregistrement..." />

            <LoadingModal
                isActive={isResyncing}
                text="Resynchronisation des données..."
            />

            <DisconnectModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleDeconnexion={handleDeconnexion}
            />

            <SavingModal
                isModalOpen={showPrompt}
                isOnline={isOnline}
                setIsFormModified={setIsFormModified}
                setIsModalOpen={setShowPrompt}
                lastUrlClicked={lastUrlClicked}
                saveAudit={saveAudit}
            />

            <ResyncModal
                isModalOpen={isResyncModalActive}
                setIsModalOpen={setIsResyncModalActive}
                isOnline={isOnline}
                getData={getData}
            />

            <AuditsNotes
                audits={audits}
                notes={notes}
                setNotes={setNotes}
                setAudits={setAudits}
                isNotesActive={isNotesActive}
                setIsNotesActive={setIsNotesActive}
                selectedAudit={selectedAudit}
            />

            <AuditsAttendus
                audits={audits}
                selectedAudit={selectedAudit}
                isAttendusActive={isAttendusActive}
                setIsAttendusActive={setIsAttendusActive}
            />

            {user && isOnline && <Footer setIsModalOpen={setIsModalOpen} />}
        </main>
    );
}

export default Layout;
