import React, { useState } from "react";
import "./styles.scss";

// Components
import AuditViewField from "./Field";
import AuditViewEtapeAncres from "./Ancres";

function AuditViewEtape({
    etape,
    activeEtape,
    inputs,
    setInputs,
    etapeData,
    handleButtonEtape,
    handleButtonSubmit,
    isLastEtape,
    canSubmitAudit,
}) {
    const [refs, setRefs] = useState({});

    return etape === activeEtape ? (
        <div className="audit-etape">
            <div className="audit-etape__fields">
                <h1 className="audit-etape__titre">{etapeData.title}</h1>

                {etapeData.fields.map((etapeField, index) => (
                    <AuditViewField
                        etape={etape}
                        inputs={inputs}
                        setInputs={setInputs}
                        title={etapeField.title}
                        description={etapeField?.description}
                        radio={etapeField.radio}
                        commentaire={etapeField.commentaire}
                        index={index}
                        refs={refs}
                        setRefs={setRefs}
                        placeholder={etapeField?.placeholder}
                        disabled={etapeField?.disabled}
                    />
                ))}

                {isLastEtape ? (
                    <button
                        type="button"
                        className="btn"
                        disabled={!canSubmitAudit}
                        onClick={(e) => handleButtonSubmit(e)}>
                        Accéder au rapport d&apos;audit
                    </button>
                ) : (
                    <button
                        type="button"
                        className="btn"
                        onClick={(e) => handleButtonEtape(e)}>
                        Étape suivante
                    </button>
                )}
            </div>

            <AuditViewEtapeAncres fields={etapeData.fields} refs={refs} />
        </div>
    ) : null;
}

export default AuditViewEtape;
