import React, { useEffect, useState, useCallback } from "react";
import "./styles.scss";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { useOutletContext, useSearchParams } from "react-router-dom";

// Composants
import LoginResponse from "./Response";

/**
 * Requête asynchrone pour authentifier l'utilisateur
 * @param {formData} formData
 * @returns JSON
 */
async function loginUser(formData) {
    return fetch(`${process.env.REACT_APP_API_HOST}/auth/`, {
        method: "POST",
        body: formData,
    })
        .then((data) => data.json())
        .catch((data) => data.json());
}

function Login() {
    const {
        token,
        setToken,
        isOnline,
        setLoadingUsers,
        setLoadingAudits,
        getUser,
        getAudits,
    } = useOutletContext();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSubmit = async (e) => {
        setIsLoading(true);
        NProgress.start();
        e.preventDefault();

        if (isOnline) {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);

            const loginResponse = await loginUser(formData);

            if (loginResponse && loginResponse.response) {
                if (loginResponse.response.token) {
                    setToken(loginResponse?.response);
                } else if (loginResponse.response.message) {
                    setResponse({
                        text: loginResponse.response.message,
                        isError: true,
                    });
                    setIsLoading(false);
                } else {
                    setResponse({
                        text: "Erreur. Veuillez ré-essayer.",
                        isError: true,
                    });
                    setIsLoading(false);
                }
            } else {
                setResponse({
                    text: "Erreur. Veuillez ré-essayer.",
                    isError: true,
                });
                setIsLoading(false);
            }

            NProgress.done();
        } else {
            setResponse({
                text: "Hors ligne.",
                isError: true,
            });
            setIsLoading(false);

            NProgress.done();
        }
    };

    // eslint-disable-next-line no-unused-vars
    const handleAutoConnexion = useCallback(
        async (urlToken) => {
            NProgress.configure({ parent: "#loading", showSpinner: false });

            setIsLoading(true);
            NProgress.start();

            const formData = new FormData();
            formData.append("token", urlToken);

            const loginResponse = await loginUser(formData);

            if (loginResponse && loginResponse.response) {
                if (loginResponse.response.token) {
                    setToken(loginResponse?.response);
                } else if (loginResponse.response.message) {
                    setResponse({
                        text: loginResponse.response.message,
                        isError: true,
                    });
                    setIsLoading(false);
                } else {
                    setResponse({
                        text: "Erreur. Token invalide.",
                        isError: true,
                    });
                    setIsLoading(false);
                }
            } else {
                setResponse({
                    text: "Erreur. Token invalide.",
                    isError: true,
                });
                setIsLoading(false);
            }

            NProgress.done();

            searchParams.delete("token");
            searchParams.delete("resync");
            setSearchParams(searchParams);
        },
        [setToken, searchParams, setSearchParams]
    );

    // Connexion automatique si token dans l'URL
    useEffect(() => {
        if (!token && !isLoading) {
            if (searchParams.has("token")) {
                const urlToken = searchParams.get("token");

                handleAutoConnexion(urlToken);
            }
        }
    }, [token, isLoading, handleAutoConnexion, searchParams]);

    // Récupération des données si token OK
    useEffect(() => {
        setLoadingUsers(true);
        setLoadingAudits(true);

        NProgress.configure({ parent: "#loading", showSpinner: false });

        if (token) {
            getUser();
            getAudits();
        }
    }, [token]);

    // On ne veut pas que l'utilisateur puisse se login si les inputs ne sont pas remplies
    useEffect(() => {
        if (email.length && password.length) {
            if (!canSubmit) {
                setCanSubmit(true);
            }
        } else {
            setCanSubmit(false);
        }
    }, [email, password, canSubmit, setCanSubmit]);

    return (
        <div className="loginPage">
            <div className="loginPage__container">
                {isLoading ? (
                    <div className="loginPage__chargement">
                        Traitement en cours
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <label>
                            <span>Email</span>
                            <input
                                type="text"
                                value={email}
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                        <label>
                            <span>Mot de passe</span>
                            <input
                                type="password"
                                value={password}
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        <input
                            type="submit"
                            className="btn"
                            disabled={!canSubmit}
                        />

                        {response && response.text && (
                            <LoginResponse
                                text={response.text}
                                isError={response.isError}
                            />
                        )}
                    </form>
                )}
                <div id="loading" className="nprogress__custom" />
            </div>
        </div>
    );
}

export default Login;
