/* eslint-disable no-nested-ternary */
import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./styles.scss";

// Composants
import HeaderButton from "./HeaderButton";

// Assets
import labelOr from "../../assets/images/label_or.png";
import labelArgent from "../../assets/images/label_argent.png";
import labelBronze from "../../assets/images/label_bronze.png";
import labelDefault from "../../assets/images/labelDefault.png";

function Header({
    audits,
    selectedAudit,
    setIsNotesActive,
    setIsAttendusActive,
    isOnline,
    isSaving,
    saveAudit,
    setIsResyncModalActive,
}) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    let contentRight = null;

    if (selectedAudit !== null && pathname !== "/") {
        if (
            audits[selectedAudit].is_submitted ||
            audits[selectedAudit].etape >= 10
        ) {
            contentRight = (
                <>
                    <HeaderButton
                        text="Détail des attendus"
                        handleClickButton={() => {
                            setIsAttendusActive(true);
                        }}
                        secondaryClass="classic"
                        iconName="icon-detail"
                    />

                    <HeaderButton
                        text="Bloc notes"
                        handleClickButton={() => {
                            setIsNotesActive(true);
                        }}
                        secondaryClass="classic"
                        iconName="icon-notes"
                    />

                    <HeaderButton
                        text="Retour à la liste des dossiers"
                        handleClickButton={() => {
                            navigate("/");
                        }}
                        secondaryClass="full"
                        iconName="icon-fat-arrow-left"
                    />
                </>
            );
        } else {
            contentRight = (
                <>
                    <HeaderButton
                        text="Détail des attendus"
                        handleClickButton={() => {
                            setIsAttendusActive(true);
                        }}
                        secondaryClass="classic"
                        iconName="icon-detail"
                    />

                    <HeaderButton
                        text="Bloc notes"
                        handleClickButton={() => {
                            setIsNotesActive(true);
                        }}
                        secondaryClass="classic"
                        iconName="icon-notes"
                    />

                    <HeaderButton
                        text={
                            isSaving
                                ? `Enregistrement en cours...`
                                : `Enregistrer`
                        }
                        handleClickButton={() => {
                            saveAudit();
                        }}
                        secondaryClass={`full${
                            !isOnline || isSaving ? " disabled" : ""
                        }`}
                        iconName="icon-save"
                    />
                </>
            );
        }
    }

    return (
        <header id="header">
            <div className="left-part">
                <Link to="/">
                    {audits &&
                    selectedAudit !== null &&
                    audits[selectedAudit] &&
                    pathname !== "/" ? (
                        <>
                            {audits[selectedAudit]?.label_vise === "or" ? (
                                <img alt="label" src={labelOr} />
                            ) : audits[selectedAudit]?.label_vise ===
                              "argent" ? (
                                <img alt="label" src={labelArgent} />
                            ) : (
                                <img alt="label" src={labelBronze} />
                            )}
                            <span className="text golf">
                                Audit du golf
                                <b>{audits[selectedAudit]?.club?.post_title}</b>
                            </span>
                        </>
                    ) : (
                        <>
                            <img alt="img" src={labelDefault} />
                            <span className="text">
                                Connaitre, agir et sensibiliser à<br />
                                la biodiversité du golf
                            </span>
                        </>
                    )}
                </Link>
            </div>

            <div className="right-part">
                <HeaderButton
                    text={isOnline ? "En ligne" : "Hors ligne"}
                    secondaryClass={`classic check-online${
                        isOnline ? " is-online" : ""
                    }`}
                />
                {isOnline && (
                    <HeaderButton
                        text="Resynchroniser"
                        secondaryClass="classic"
                        iconName="icon-spinner"
                        handleClickButton={() => setIsResyncModalActive(true)}
                    />
                )}
                {selectedAudit !== null && pathname !== "/" ? (
                    contentRight
                ) : (
                    <a
                        href={process.env.REACT_APP_PLATEFORME_LINK}
                        target="_blank"
                        className="header-button small-mobile full"
                        rel="noreferrer">
                        <span className="icon-arrow-small-right" />
                        Accéder à la plateforme
                    </a>
                )}
            </div>
        </header>
    );
}

export default Header;
