import React from "react";
import {
    RouterProvider,
    Route,
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import "./styles/styles.scss";

// Composants
import Dashboard from "./components/Dashboard";
import ErrorBoundary from "./components/Layout/ErrorBoundary";

// Custom Hooks
import AuditView from "./components/Audits/View";
import Layout from "./components/Layout";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />} errorElement={<ErrorBoundary />}>
            <Route index element={<Dashboard />} />
            <Route path="/audits/:id" element={<AuditView />} />
        </Route>
    )
);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
