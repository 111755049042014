import React, { useEffect, useState } from "react";
import "./styles.scss";

// Components
import Overlay from "../../Overlay";
import AuditsAttendu from "./Attendu";

// Data
import dataAttendus from "./data";

function AuditsAttendus({
    audits,
    selectedAudit,
    isAttendusActive,
    setIsAttendusActive,
}) {
    const [niveauLabellisation, setNiveauLabellisation] = useState(false);

    useEffect(() => {
        if (
            audits &&
            selectedAudit !== null &&
            audits[selectedAudit]?.label_vise
        ) {
            setNiveauLabellisation(audits[selectedAudit]?.label_vise);
        }
    }, [audits, selectedAudit]);

    return (
        <Overlay
            title={
                niveauLabellisation ? `Détail des attendus du` : "Pas de niveau"
            }
            titleSub={
                niveauLabellisation
                    ? `niveau ${niveauLabellisation[0].toUpperCase()}${niveauLabellisation
                          .slice(1)
                          .toLowerCase()}`
                    : ""
            }
            isActive={isAttendusActive}
            className="overlay-attendus"
            handleOverlayClose={() => setIsAttendusActive(false)}>
            {niveauLabellisation && dataAttendus[niveauLabellisation] ? (
                <>
                    <p className="text overlay-attendus__introduction">
                        <strong>Philosophie du niveau : </strong>
                        {dataAttendus[niveauLabellisation].introduction}
                    </p>
                    {dataAttendus[niveauLabellisation].attendus.map(
                        (attendu) => (
                            <AuditsAttendu
                                title={attendu?.title}
                                details={attendu?.details}
                                justificatifs={attendu?.justificatifs}
                                informations={attendu?.informations}
                                selectedAudit={selectedAudit}
                                niveauLabellisation={niveauLabellisation}
                            />
                        )
                    )}
                </>
            ) : null}
        </Overlay>
    );
}

export default AuditsAttendus;
